<template>
<section class="px-3 mt-4">
    <section class="flex justify-start">
        <n-icon
            size="20"
            class="text-gray-600 dark:text-white/75"
            @click="router.go(-1)">
            <ArrowLeft12Regular />
        </n-icon>
    </section>

    <n-alert v-if="bots.localization['bots_how_to_create_bot']" type="info" class="rounded-md mt-4">
        <template #header>
            <div class="notes text-xs pt-0 -mt-1" v-html="bots.localization['bots_how_to_create_bot']"></div>
        </template>
    </n-alert>

    <section v-if="init" class="pb-16">
        <!-- <n-alert v-if="isExchangeFutures" class="mt-4 rounded-md" type="warning">
            <div class="notes text-xs" v-html="bots.localization['bots/simulate_mode/not_available']"></div>
        </n-alert> -->

        <n-card
            size="small"
            :bordered="false"
            class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-md mt-4"
            :segmented="{
                content: true,
                footer: 'soft'
            }">
            <template #default>
                <!-- simulate v-if="!isExchangeFutures" -->
                <rb-checkbox
                    class="mb-4"
                    :help-warning="dataForm.settings.simulate ? bots.localization['bots_simulation_notes'] : null"
                    :label="'- ' + bots.localization['simulate_button']"
                    v-model:checked="dataForm.settings.simulate" />
                
                <div v-if="dataForm.settings.simulate" class="mt-4">
                    <n-alert class="rounded-md" type="warning" size-small>
                        <div
                            class="notes text-[10px]"
                            v-html="bots.localization['run_simulation_after_specified_date_info'] || 'Вы можете задать момент старта (дата и время) работы бота в режиме симуляции по имеющимся данным изменения цены торговой пары в прошлом. По умолчанию симуляция проводится за последние 60 дней.'">
                        </div>
                    </n-alert>

                    <section class="mt-2">
                        <rb-checkbox
                            class="mt-2"
                            :label="bots.localization['run_simulation_after_specified_date'] || 'Запустить симуляцию после заданной даты'"
                            :checked="!!~dataForm.start_filters.findIndex(({ id }) => id == 'simulate_start_time:check:time')"
                            @click="triggerFilter('simulate_start_time:check:time')" />

                        <template v-if="!!~dataForm.start_filters.findIndex(({ id }) => id == 'simulate_start_time:check:time')">
                            <n-popover
                                v-model:show="showStartTime"
                                class="rounded-md"
                                placement="bottom-end"
                                trigger="click">
                                <template #trigger>
                                    <n-button
                                        secondary
                                        type="default"
                                        class="rounded-md flex items-center px-3 mt-4 w-full"
                                        @click="showStartTime = true">
                                        <div class="w-full flex justify-between items-center font-semibold">
                                            <span class="mr-2">
                                                {{ dataForm.start_filters[hasFilter('simulate_start_time:check:time')].value }}
                                            </span>
                                            <n-icon size="20">
                                                <CalendarLtr32Regular />
                                            </n-icon>
                                        </div>
                                    </n-button>
                                </template>
                                <section class="flex">
                                    <n-date-picker
                                        panel
                                        class="mt-2"
                                        type="datetime"
                                        size="large"
                                        :actions="null"
                                        :is-date-disabled="dateDisabled"
                                        :value="new Date(dataForm.start_filters[hasFilter('simulate_start_time:check:time')].value).getTime()"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                        @update:value="setStartTime(hasFilter('simulate_start_time:check:time'), $event)" />
                                </section>
                            </n-popover>
                        </template>
                    </section>
                    <n-divider />
                </div>

                <!-- name -->
                <rb-input
                    id="name"
                    class="mb-4"
                    :label="dataForm.name.title"
                    :help="dataForm.name.dataHelp"
                    :status="!!bots.errorsForm?.name ? 'error' : undefined"
                    :msg="bots.errorsForm?.name?.msg"
                    :placeholder="dataForm.name.placeholder"
                    v-model:value="dataForm.name.value"
                    @update:value="delete bots.errorsForm.name" />

                <!-- exchange -->
                <div class="flex justify-between items-end">
                    <rb-select
                        filterable
                        class="flex-grow"
                        :help="dataForm.exchange.dataHelp"
                        :help-warning="exchangeMeta3Info ? exchangeMeta3Info : null"
                        :label="dataForm.exchange.title"
                        :options="exchangesSorted"
                        :loading="dataForm.exchange.loading"
                        :status="dataForm.exchange.status === 'error' ? 'error' : undefined"
                        :msg="dataForm.exchange.msg"
                        v-model:value="dataForm.exchange.value"
                        @update:value="dataForm.exchange.status = null, dataForm.exchange.msg = null" />
                    <span
                        v-if="dataForm.exchange.value !== -1"
                        class="inline-block rb-td-stock-icon ml-4 w-16 h-8"
                        :class="`rb-td-stock-icon-${exchange(dataForm.exchange.value)?.code_name}`">
                    </span>
                </div>

                <!-- api-key -->
                <template v-if="dataForm.api_key.show">
                    <n-divider />
                    <rb-api-key-cr
                        :only-create="false"
                        :like-a-modal="false"
                        :data="{
                            show_dashboard: true,
                        }"
                        :label="{
                            helpers: [{
                                type: 'info',
                                content: dataForm.api_key.dataHelp,
                            }]
                        }"
                        :exchange-id="dataForm.exchange.value"
                        :api-keys-list="apiKeysRefs"
                        v-model:api-key-value="dataForm.api_key.value"
                        @addNewApiKey="setApiKey($event)">
                    </rb-api-key-cr>
                </template>

                <!-- pair -->
                <template v-if="dataForm.pair.show">
                    <n-divider />
                    <rb-pairs
                        id="pair"
                        :pairsRefs="pairsRefs"
                        :loading="dataForm.pair.loading"
                        :recommendedPairsRef="recommendedPairsRef"
                        :tickerMessage="tickerMessage"
                        :label="dataForm.pair.label"
                        :title="dataForm.pair.title"
                        :dataHelp="dataForm.pair.dataHelp"
                        :howToChooseInfo="dataForm.pair.howToChooseInfo"
                        :gridNavLabel="dataForm.pair.gridNavLabel"
                        :recommendedPairsInfo="dataForm.pair.recommendedPairsInfo"
                        :status="!!bots.errorsForm?.pair ? 'error' : undefined"
                        :msg="bots.errorsForm?.pair?.msg"
                        v-model="dataForm.pair.value"
                        @update:modelValue="delete bots.errorsForm.pair" />
                </template>

                <!-- algo -->
                <template v-if="dataForm.algo.show">
                    <n-divider />
                    <rb-select
                        id="algo"
                        :label="dataForm.algo.title"
                        :options="algoOptions"
                        :help="dataForm.algo.dataHelp"
                        :status="!!bots.errorsForm?.algo ? 'error' : undefined"
                        :msg="bots.errorsForm?.algo?.msg"
                        v-model:value="dataForm.algo.value"
                        @update:modelValue="delete bots.errorsForm.algo" />
                </template>
            </template>
        </n-card>

        <n-card
            v-if="dataForm.wallet.show"
            id="wallet"
            size="small"
            :bordered="false"
            class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg mt-4 h-full"
            :segmented="{
                content: true,
                footer: 'soft'
            }">
            <template #default>
                <!-- wallet -->
                <bot-wallet
                    :data="dataForm.wallet"
                    :apiKeysRefs="apiKeysRefs"
                    :coinSymbolForWallet="coinSymbolForWallet"
                    :exchangeInfoRef="exchangeInfo"
                    :exchangeRef="dataForm.exchange.value"
                    :pairWalletsRefs="pairWalletsRefs"
                    :api-key="dataForm.api_key.value"
                    :status="!!bots.errorsForm?.wallet ? 'error' : undefined"
                    :msg="bots.errorsForm?.wallet?.msg"
                    v-model="dataForm.wallet.value"
                    @addNewWallet="addNewWallet($event)"
                    @updateWallet="updateWallet($event)"
                    @update:modelValue="delete bots.errorsForm.wallet" />

                <!-- shuolder -->
                <template v-if="isExchangeFutures && isWalletSelected">
                    <n-divider />
                    <rb-shoulder
                        :help-warning="dataForm.leverage.info"
                        :pair="leverageMinMax"
                        :disabled="!isWalletSelected"
                        :label="dataForm.leverage.label"
                        v-model="dataForm.leverage.value">
                        <template #messages>
                            <div v-if="dataForm.leverage.status === 'error'" class="w-full mt-2 text-red-400 text-right">{{ dataForm.leverage.msg }}</div>
                        </template>
                    </rb-shoulder>
                </template>

                <!-- positionmode -->
                <template v-if="isExchangeFutures && isWalletSelected">
                    <n-divider />
                    <bot-positionmode/>
                </template>

                <!-- wallet commissions -->
                <template v-if="isWalletSelected && exchangeCommissionCoin && !dataForm.settings.simulate">
                    <n-divider />
                    <bot-wallet-commissions
                        :isUpdating="isUpdating"
                        :data="dataForm.comm_type"
                        :dataForm="dataForm"
                        :exchangeCommissionCoin="exchangeCommissionCoin"
                        v-model="dataForm.comm_type.value" />
                </template>
            </template>
        </n-card>

        <n-card
            v-if="isWalletSelected"
            size="small"
            :bordered="false"
            class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg mt-4 h-full"
            :segmented="{
                content: true,
                footer: 'soft'
            }">
            <template #default>
                <!-- presets -->
                <bot-presets/>
            </template>
        </n-card>

        <n-card
            v-if="isWalletSelected"
            size="small"
            :bordered="false"
            class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg mt-4 h-full"
            :segmented="{
                content: true,
                footer: 'soft'
            }">
            <template #default>
                <!-- bot settings -->
                <bot-settings
                    :exchangeInfoRef="exchangeInfo" />
            </template>
        </n-card>

        <template v-if="isWalletSelected">
            <!-- Additional Settings -->
            <n-card
                size="small"
                :bordered="false"
                class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg mt-4 h-full"
                :segmented="{
                    content: true,
                    footer: 'soft'
                }">
                <template #default>
                    <bot-additional-settings
                        class="mt-4"
                        :walletsRefs="walletsRefs"/>
                </template>
            </n-card>

            <!-- stop-loss -->
            <n-card
                size="small"
                :bordered="false"
                class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg mt-4 h-full"
                :segmented="{
                    content: true,
                    footer: 'soft'
                }">
                <template #default>
                    <bot-stop-loss
                        class="mt-4"
                        :pairsRefs="pairsRefs" />
                </template>
            </n-card>

            <!-- Auto Inversion -->
            <n-card
                v-if="!isSimulate"
                size="small"
                :bordered="false"
                class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg mt-4 h-full"
                :segmented="{
                    content: true,
                    footer: 'soft'
                }">
                <template #default>
                    <bot-auto-inversion
                        class="mt-4"
                        :dataForm="dataForm"
                        @update:algo_autoinversion_enabled="dataForm.algo_autoinversion_enabled = $event"
                        @update:algo_autoinversion_bot_id="dataForm.algo_autoinversion_bot_id = $event" />
                </template>
            </n-card>

            <!-- Switch Trading Pair -->
            <n-card
                size="small"
                :bordered="false"
                class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg mt-4 h-full"
                :segmented="{
                    content: true,
                    footer: 'soft'
                }">
                <template #default>
                    <bot-switch-trading-pair
                        class="mt-4"
                        :isExchangeCoin="isExchangeCoin"
                        :symbolsForMarketRefs="symbolsForMarketRefs" />
                </template>
            </n-card>

            <!-- Bot Startn Filters -->
            <n-card
                size="small"
                :bordered="false"
                class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg mt-4 h-full"
                :segmented="{
                    content: true,
                    footer: 'soft'
                }">
                <template #default>
                    <bot-start-filters
                        class="mt-4"
                        :pairsRefs="pairsRefs" />
                </template>
            </n-card>

            <!-- Bot Notifications -->
            <n-card
                size="small"
                :bordered="false"
                class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg mt-4 h-full"
                :segmented="{
                    content: true,
                    footer: 'soft'
                }">
                <template #default>
                    <bot-notifications class="mt-4" />
                </template>
            </n-card>

            <!-- Bot Extended Settings -->
            <n-card
                size="small"
                :bordered="false"
                class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg mt-4 h-full"
                :segmented="{
                    content: true,
                    footer: 'soft'
                }">
                <template #default>
                    <bot-extended-settings class="mt-4" />
                </template>
            </n-card>
        </template>

        <div class="fixed w-full left-0 bottom-12 py-2 z-10 px-3">
            <div class="flex justify-end">
                <n-button
                    strong
                    class="rounded-md text-white/90 uppercase w-full"
                    :color="gl.mainColor"
                    :disabled="!canCreateBot"
                    @click="onApplyClicked">
                    {{ bots.localization['bot_create_button'] }}
                </n-button>
            </div>
        </div>
    </section>
</section>
</template>

<script>
// general
import general from './general';

// icons
import { Warning24Filled, ArrowLeft12Regular, QuestionCircle20Filled } from '@vicons/fluent';

// components
import RbInput from '@components/rb-input/mobile.vue';
import ApiKeys from '@components/api-keys/mobile.vue';
import BotApiKey from '@components/bots/childs/bot-api-key.vue';
import BotWallet from '@components/bot-wallet/mobile.vue';
import BotPresets from '@components/bots/childs/bot-presets/mobile.vue';
import RbSelect from '@components/rb-select/mobile.vue';
import BotSettings from '@components/bots/childs/bot-settings/mobile.vue';
import RbShoulder from '@components/rb-shoulder';
import RbCheckbox from '@components/rb-checkbox/mobile.vue';
import RbPairs from '@components/rb-pairs/mobile.vue';
import Bots__running from '@/components/svg/Bots__running.vue';
import BotPositionmode from '@components/bots/childs/bot-positionmode';
import BotStartFilters from '@components/bots/childs/bot-start-filters/mobile.vue';
import BotNotifications from '@components/bots/childs/bot-notifications/mobile.vue';
import BotAutoInversion from '@components/bots/childs/bot-auto-inversion/mobile.vue';
import BotWalletCommissions from '@components/bots/childs/bot-wallet-commissions';
import BotSwitchTradingPair from '@components/bots/childs/bot-switch-trading-pair/mobile.vue';
import BotAdditionalSettings from '@components/bots/childs/bot-additional-settings/mobile.vue';
import BotExtendedSettings from '@components/bots/childs/bot-extended-settings/mobile.vue';
import RbWalletSelectV2 from '@components/rb-wallet-select-v2';
import BotStopLoss from '@components/bots/childs/bot-stop-loss/mobile.vue';
import RbApiKeyCr from '@components/rb-api-key-cr';

// naive-ui
import {
    NIcon,
    NCard,
    NAlert,
    NInput,
    NSelect,
    NButton,
    NDivider,
    NPopover,
    NCheckbox,
    NCollapse,
    NDatePicker,
    NCollapseItem } from 'naive-ui';

export default {
    name: 'bots-create-mobile',
    props: {
        gridAction: String,
    },
    components: {
        NIcon,
        NCard,
        NAlert,
        NInput,
        RbInput,
        NButton,
        NSelect,
        RbPairs,
        ApiKeys,
        RbSelect,
        NPopover,
        NDivider,
        BotWallet,
        NCheckbox,
        BotApiKey,
        NCollapse,
        RbApiKeyCr,
        RbShoulder,
        BotPresets,
        RbCheckbox,
        BotStopLoss,
        BotSettings,
        NDatePicker,
        NCollapseItem,
        Bots__running,
        BotStartFilters,
        RbWalletSelectV2,
        BotPositionmode,
        Warning24Filled,
        BotAutoInversion,
        BotNotifications,
        ArrowLeft12Regular,
        BotExtendedSettings,
        BotSwitchTradingPair,
        BotWalletCommissions,
        BotAdditionalSettings,
        QuestionCircle20Filled,
    },
    setup(props) {
        
        return {
            ...general(...arguments),
        };
    },
};
</script>

<style lang="scss">
@import '@/css/stock-icon.scss';

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>